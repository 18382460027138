import Vue from 'vue'
import VueRouter from 'vue-router'

import tokenMgt from "@/utils/token_mgt.js"

Vue.use(VueRouter)

import TestView from '../views/Test.vue'

const lazyLoadVue = function (view) {
  return () => import(`@/views/${view}.vue`)
}

function requireAuth(to, from, next) {

  console.log('check Auth Required.........')
  // cek apakah token valid
  // jika valid lanjut
  // jika tidak valid redirect ke login

  tokenMgt.checkLogin()
    .then((result) => {
      console.log('check login result', result)
      if (result) {
        next()
      } else {
        next({
          path: '/login',
        })
      }
    })
  // let tokenvalid = tokenMgt.checkLogin()

  // if (tokenvalid) {
  //   next()
  // } else {
  //   // next()
  //   next({
  //     path: '/login',
  //   })
  // }
}

// function checkBase(to, from, next) {

//   console.log('check base')
//   let tokenvalid = tokenMgt.checkLogin()

//   if (tokenvalid) {
//     next({
//       path: '/super',
//     })
//   } else {
//     // next()
//     next({
//       path: '/login',
//     })
//   }
// }



const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')

  },
  // {
  //   path: '/super',
  //   name: 'app_super',
  //   component: lazyLoadVue('ScreenView'),
  //   // beforeEnter: requireAuth,
  //   // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/',
    name: 'main',
    // beforeEnter: checkBase,
    // component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    component: () => import(/* webpackChunkName: "about" */ '../views/BaseView.vue'),

  },

  {
    path: '/',
    name: 'main',
    // beforeEnter: checkBase,
    // component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    component: () => import(/* webpackChunkName: "about" */ '../views/BaseView.vue'),

    children: [
      {
        path: '',
        name: 'home',
        // beforeEnter: requireAuth,
        component: TestView
      },
      {
        path: '/super',
        name: 'app_super',
        component: lazyLoadVue('ScreenView'),
        beforeEnter: requireAuth,
        // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
      },
      {
        path: '/super/:routename(.*)',
        name: 'app_super',
        component: lazyLoadVue('ScreenView'),
        beforeEnter: requireAuth,
        // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
      },

    ]
  },
  {
    path: '/app',
    name: 'homeapp',
    beforeEnter: requireAuth,
    component: TestView
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
