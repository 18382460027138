// standard token management
// Fitur: 
// - buka token
// - simpan token
// - check expired token
// - request token baru (tbd)

// import jwt
import jwt_decode from "jwt-decode"

const openToken = async function(vtoken){
  // buka token dengan menggunakan jwt
  // tidak perlu dicek signaturenya
  // karena sudah dicek di server
  
  let result = jwt_decode(vtoken)
  return result
}

const saveToken = async function(vtoken, vname='TOKEN'){
  // cukup simpan jwt-nya saja
  localStorage.setItem(vname, vtoken)
  return vname
}

const checkToken = async function(vtoken){
  // buka token, cek apakah field exp yang isinya unix time expired atau tidak
  // jika expired, return false
  // jika tidak expired, return token yang sudah dibuka
  let vdata = await openToken(vtoken)
  let vexp = vdata.exp
  let vnow = Math.floor(Date.now() / 1000)
  console.log('compare time...', vnow, vexp)
  if (vexp < vnow) {
    return false
  } else {
    return vdata
  }
}

const checkLogin = async function(vname='TOKEN') {
  // cek apakah ada token di localstorage
  // jika ada, cek apakah token sudah expired atau belum
  // jika sudah expired, return false
  // jika belum expired, return token yang sudah dibuka
  let vtoken = localStorage.getItem(vname)
  if (vtoken) {
    let vdata = await checkToken(vtoken)
    if (vdata) {
      return vdata
    } else {
      return false
    }
  }
}



export default {
  openToken,
  checkToken,
  saveToken,
  checkLogin,
}
